body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Rubik", sans-serif;
  background: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-bottom: 0px;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-btn {
  padding: 0px 16px;
  height: 40px;
  background: #5468ff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  border: 2px solid transparent;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  line-height: 38px;

  &:hover {
    background: #364df7;
    color: #ffffff;
  }

  &.disable-btn {
    background: #c8caff;
    pointer-events: none;
  }
}

.icon-btn {
  background: #eaeaff;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5468ff;
  text-decoration: none;
  margin-left: 10px;

  &:hover {
    background: #5468ff;
    color: #ffffff;
  }

  i {
    font-weight: 700;
  }
}

.loader {
  display: inline-block;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #000;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  margin-bottom: -3px;
  -webkit-animation: spin 0.4s linear infinite; /* Safari */
  animation: spin 0.4s linear infinite;
}

.tag-lebel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  width: 250px;
}

// utils

.flex {
  display: flex;
  gap: 16px;
}

.btn {
  display: block;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid transparent;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  &-active {
    background: #5468ff;
    color: #fff;
  }

  &-default {
    color: #5468ff;
    border: 1px solid #5468ff;
  }

  &:hover {
    background: #5468ff;
    color: #fff;
  }
}

.m {
  &-t {
    &-2 {
      margin-top: 2px;
    }
    &-4 {
      margin-top: 4px;
    }
    &-8 {
      margin-top: 8px;
    }
    &-16 {
      margin-top: 16px;
    }

    &-32 {
      margin-top: 32px;
    }

    &-48 {
      margin-top: 48px;
    }
  }

  &-b {
    &-2 {
      margin-bottom: 2px;
    }
    &-4 {
      margin-bottom: 4px;
    }
    &-8 {
      margin-bottom: 8px;
    }
    &-16 {
      margin-bottom: 16px;
    }
    &-32 {
      margin-bottom: 32px;
    }
    &-48 {
      margin-bottom: 48px;
    }
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 350px;
}
