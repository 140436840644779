/* ===== navbar-area css ===== */

.navbar-area {
  padding: 12px 0;

  .logo {
    a {
      font-size: 36px;
      font-weight: 600;
      color: #000;
    }
  }
}

.mobile-menu-btn {
  margin-left: 16px;
  button {
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    font-size: 28px;
    font-weight: 700;
  }
}

.menuWrapper {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  & > nav {
    & > ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      list-style: none;
      margin-bottom: 0px;
      transition: all 0.3s;

      @media (max-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
        background: #fff;
        border-radius: 8px;
        position: absolute;
        z-index: 99;
        top: 110%;
        right: 0;
        width: 250px;
        padding: 16px 20px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        opacity: 0;
        visibility: hidden;

        &.active {
          opacity: 1;
          visibility: visible;
          top: 100%;
        }
      }

      & > li {
        padding-right: 20px;

        &:hover {
          & > a {
            color: #5468ff;
          }
        }

        button,
        a {
          font-size: 18px;
          font-weight: 500;
          color: #333;
          line-height: 40px;
        }
      }
    }
  }
}
