/* ==== AccountSettings CSS ==== */

.accountSettings {
  padding-top: 30px;

  .title {
    h2 {
      font-size: 36px;
      line-height: 45px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 16px;
    }
  }

  &__box {
    margin-bottom: 16px;

    .subtitle {
      background: #fafafa;
      border-radius: 8px;
      margin: 2px 0px;
      padding: 16px 20px;

      h4 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 400;
        color: #000000;
        margin: 0;
      }
    }

    &__info {
      background: #fff;
      border-radius: 8px;
      padding: 32px;
      margin: 2px 0px;

      .single-input {
        margin-bottom: 24px;

        label {
          font-size: 14px;
          color: #828282;
          display: block;
          padding-left: 6px;
        }

        input {
          width: 100%;
          border: none;
          border-bottom: 2px solid #bdbdbd;
          padding: 6px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          &:focus {
            outline: none;
            border-color: #5468ff;
          }

          &::placeholder {
            color: inherit;
          }
        }

        textarea {
          width: 100%;
          border: none;
          border-bottom: 2px solid #bdbdbd;
          padding: 6px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
  }
}

.preview-image {
  .file-upload {
    position: relative;
    max-width: 350px;
    width: 100%;
    height: 200px;
    border: 1px dashed rgba(0, 0, 0, 0.3);
    margin-bottom: 32px;
    border-radius: 4px;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      appearance: none;
      z-index: 9;
    }

    label {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      i {
        font-size: 24px;
        color: #7d7d7d;
        font-weight: 700;
        margin-bottom: 16px;
      }
      span {
        font-size: 18px;
        color: #7d7d7d;
      }
    }
  }
}
